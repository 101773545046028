var exports = {};
exports = {
  trueFunc: function trueFunc() {
    return true;
  },
  falseFunc: function falseFunc() {
    return false;
  }
};
export default exports;
export const trueFunc = exports.trueFunc;